import { ref, watch, WatchStopHandle, nextTick, computed } from 'vue'
import { usePage } from '@inertiajs/vue3'
import { CurrencyInfo } from '@/Pages/the-city/interfaces/Filters'

const localStorageCurrencyKey = 'currentCurrency'
const localStorageCurrencyForServerKey = 'currencyForServer'


export const activeCurrency = computed(() => usePage().props.activeCurrency)

export const currentCurrency = ref('')

export const currencyForServer = ref('')

export const showCurrencyModal = ref(false)

const isUserInitiatedChange = ref(false)

export const handleShowCurrencyModal = (): void => {
    showCurrencyModal.value = !showCurrencyModal.value
}

export const setCurrentCurrency = (currency: string): void => {
    currentCurrency.value = currency
}

export const setCurrencyForServer = (currency: string): void => {
    currencyForServer.value = currency
}

export function currencyInnit (): void {
    const storedCurrency = localStorage.getItem(localStorageCurrencyKey)
    const currencyForServer = localStorage.getItem(localStorageCurrencyForServerKey)

    if (storedCurrency && currencyForServer) {
        setCurrentCurrency(storedCurrency)
        setCurrencyForServer(currencyForServer)
    }
}

export function currencyUpdate (dataForClient: string, dataForServer: string): void {
    isUserInitiatedChange.value = true

    localStorage.setItem(localStorageCurrencyKey, dataForClient)
    localStorage.setItem(localStorageCurrencyForServerKey, dataForServer)
}

export function useCurrencyWatcher (callback: (newCurrency: string, oldCurrency: string) => void): WatchStopHandle {
    return watch(() => currencyForServer.value, (newCurrency, oldCurrency) => {
        if (!oldCurrency || newCurrency === oldCurrency) {
            return
        }

        if (isUserInitiatedChange.value) {
            callback(newCurrency, oldCurrency)

            nextTick(() => {
                isUserInitiatedChange.value = false
            })
        }
    })
}

export function formatCurrency (value: number, currencyInfo: CurrencyInfo): string {
    return `${ currencyInfo.prefix }${ value }${ currencyInfo.suffix }`
}

export const showCurrencyAndScrollTop = (): void => {
    if (window.scrollY === 0) {
        // Already at top, show modal immediately
        handleShowCurrencyModal()
        return
    }
    
    window.scrollTo({ top: 0, behavior: 'smooth' })
    window.addEventListener('scroll', checkIfScrolledToTop)
}

const checkIfScrolledToTop = (): void => {
    if (window.scrollY === 0) {
        handleShowCurrencyModal()
        window.removeEventListener('scroll', checkIfScrolledToTop)
    }
}